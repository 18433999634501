const axios = require('./axios');
const {
    isBrowser,
    getGoogleAuthInstance,
    getFacebookAccessToken
} = require('./helper');

const LOCALSTORAGE_IDENTIFIER = 'docugate_token';

let ACCOUNT_ID = null;
let OPTIONS = {
    googleClientID:
        '24960986372-r7ffoff42glaquvf7imed2ms8k3pltam.apps.googleusercontent.com'
};

const DocuGate = {};
DocuGate.configure = (accountID, options) => {
    ACCOUNT_ID = accountID;
    OPTIONS = options;
    axios.defaults.headers.common.AccountID = ACCOUNT_ID;
};

DocuGate.loginByAuth = async (email, password) => {
    const {data} = await axios.post('/v1/auth/login', {
        email,
        password
    });
    const {token} = data.tokens.access;
    if (isBrowser) {
        localStorage.setItem(LOCALSTORAGE_IDENTIFIER, token);
    }
    return token;
};

DocuGate.registerByAuth = async (payload) => {
    const {data} = await axios.post('/v1/auth/register', {
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        password: payload.password,
        phoneNumber: payload.phoneNumber || ''
    });
    const {token} = data.tokens.access;
    if (isBrowser) {
        localStorage.setItem(LOCALSTORAGE_IDENTIFIER, token);
    }
    return token;
};

DocuGate.loginByGoogle = async (_idToken, _signInType) => {
    // const instance = await getGoogleAuthInstance(OPTIONS.googleClientID);
    // const res = await instance.signIn();

    const {data} = await axios.post('/v1/auth/google', {
        token: _idToken,
        signInType: _signInType
    });
    const {token} = data.tokens.access;

    localStorage.setItem(LOCALSTORAGE_IDENTIFIER, token);
    return token;
};

DocuGate.registerByGoogle = async () => {
    const instance = await getGoogleAuthInstance(OPTIONS.googleClientID);
    const res = await instance.signIn();

    const {data} = await axios.post('/v1/google/register', {
        idToken: res.getAuthResponse().id_token
    });

    const {token} = data;

    localStorage.setItem(LOCALSTORAGE_IDENTIFIER, token);
    return token;
};

DocuGate.loginByFacebook = async () => {
    const accessToken = await getFacebookAccessToken(OPTIONS.facebookAppID);

    const {data} = await axios.post('/v1/facebook/login', {
        accessToken
    });

    const {token} = data;

    localStorage.setItem(LOCALSTORAGE_IDENTIFIER, token);
    return token;
};

DocuGate.registerByFacebook = async () => {
    const accessToken = await getFacebookAccessToken(OPTIONS.facebookAppID);

    const {data} = await axios.post('/v1/facebook/register', {
        accessToken
    });

    const {token} = data;

    localStorage.setItem(LOCALSTORAGE_IDENTIFIER, token);
    return token;
};

// Forgot Password

DocuGate.forgotPassword = async (_email) => {
    const {data} = await axios.post('/v1/auth/forgot-password', {
        email: _email
    });

    return data;
};

// Profile

DocuGate.getProfile = async () => {
    const {data} = await axios.get('/v1/profile');
    return data;
};

DocuGate.getProfileSubscription = async () => {
    const {data} = await axios.get('/v1/profile/subscription').catch((err) => {
        throw err;
    });
    return data;
};

DocuGate.getProfileBilling = async (
    page = 0,
    limit = 5,
    sortBy = 'billingDate:asc'
) => {
    const {data} = await axios
        .get(`/v1/profile/billing?page=${page}&limit=${limit}&sortBy=${sortBy}`)
        .catch((err) => {
            throw err;
        });
    return data;
};

DocuGate.updateProfile = async (payload) => {
    const {data} = await axios.patch('/v1/profile', payload);
    return data;
};

DocuGate.getBillingPaymentLink = async (billingId) => {
    const {data} = await axios.get(
        `/v1/profile/billing/${billingId}/payment-link`
    );
    return data;
};

// Flow
DocuGate.insertFlowHeader = async (paramBody) => {
    const {data} = await axios.post('/v1/flow', paramBody);
    return data;
};

DocuGate.getPaginatedFlowHeader = async (page = 1, limit = 10) => {
    const paramQuery = {
        page,
        limit
    };

    const {data} = await axios.get('/v1/flow', {
        params: paramQuery
    });
    return data;
};

DocuGate.getFlowDetailByHeaderId = async (headerId) => {
    const {data} = await axios.get(`/v1/flow/${headerId}/detail`);
    return data;
};

DocuGate.getFlowHeaderByHeaderId = async (headerId) => {
    const {data} = await axios.get(`/v1/flow/${headerId}`);
    return data;
};

DocuGate.getFlowDetailByDetailId = async (headerId, detailId) => {
    const {data} = await axios.get(`/v1/flow/${headerId}/detail/${detailId}`);
    return data;
};

DocuGate.updateFlowDetailByDetailId = async (headerId, detailId, request) => {
    const {data} = await axios.put(
        `/v1/flow/${headerId}/detail/${detailId}`,
        request
    );
    return data;
};

DocuGate.deleteFlowHeaderById = async (headerId, detailId, request) => {
    const {data} = await axios.post(
        `/v1/flow/${headerId}/headerDelete`,
        request
    );
    return data;
};

DocuGate.deleteFlowByFlowHeaderId = async (headerId) => {
    const {data} = await axios.delete(`/v1/flow/${headerId}`);
    return data;
};

// Subscriptions
DocuGate.getAvailablePackages = async () => {
    const {data} = await axios.get('/v1/packages');
    return data;
};

DocuGate.postSubscribePackages = async (request) => {
    const {data} = await axios.post('/v1/subscribe', request);
    return data;
};

DocuGate.getBillingById = async (id) => {
    const url = `/v1/billing/${id}`;
    const {data} = await axios.get(url);
    return data;
};

DocuGate.getBillingPayment = async (billingId) => {
    const {data} = await axios.get(`/v1/billing/${billingId}/payment`);
    return data;
};

DocuGate.getBillingUnpaid = async () => {
    const {data} = await axios.get(`/v1/billing/unpaid`);
    return data;
};

DocuGate.getPaymentMethod = async () => {
    const {data} = await axios.get(`/v1/subscribe/payment-method`);
    return data;
};

// Documents
DocuGate.getDocuments = async (
    page = 0,
    limit = 5,
    sortBy = 'createdAt:asc'
) => {
    const {data} = await axios
        .get(`/v1/documents?page=${page}&limit=${limit}&sortBy=${sortBy}`)
        .catch((err) => {
            throw err;
        });
    return data;
};

DocuGate.getDocumentsArchieve = async (
    page = 0,
    limit = 5,
    sortBy = 'createdAt:asc'
) => {
    const {data} = await axios
        .get(
            `/v1/documents/archieve?page=${page}&limit=${limit}&sortBy=${sortBy}`
        )
        .catch((err) => {
            throw err;
        });
    return data;
};

DocuGate.getDocumentSigning = async (documentId = '') => {
    const {data} = await axios
        .get(`/v1/documents/signing/${documentId}`)
        .catch((err) => {
            throw err;
        });
    return data;
};

DocuGate.crudDocumentSigningCoordinate = async (
    method,
    documentId,
    payload
) => {
    if (method === 'PUT') {
        const {data} = await axios
            .put(`/v1/documents/signing/${documentId}/coordinate`, payload)
            .catch((err) => {
                throw err;
            });
        return data;
    }
    if (method === 'DELETE') {
        // console.log(payload);

        const {data} = await axios
            .delete(
                `/v1/documents/signing/${documentId}/coordinate/${payload.id}`,
                payload
            )
            .catch((err) => {
                throw err;
            });
        return data;
    }
    const {data} = await axios
        .post(`/v1/documents/signing/${documentId}/coordinate`, payload)
        .catch((err) => {
            throw err;
        });
    return data;
};

DocuGate.putDocumentSigningResponse = async (
    documentId,
    coordinateId,
    payload
) => {
    const {data} = await axios
        .put(
            `/v1/documents/signing/${documentId}/coordinate/${coordinateId}`,
            payload
        )
        .catch((err) => {
            throw err;
        });
    return data;
};

DocuGate.postDocumentSigningInvite = async (documentId) => {
    const {data} = await axios
        .post(`/v1/documents/signing/${documentId}/invite`)
        .catch((err) => {
            throw err;
        });
    return data;
};

// User Signing
DocuGate.getUserSigningValidation = async (token = '') => {
    const {data} = await axios
        .get(`/v1/documents/user-sign/validate?token=${token}`)
        .catch((err) => {
            throw err;
        });
    return data;
};

DocuGate.getUserSigningDocumentSigning = async (
    documentId = '',
    token = ''
) => {
    const {data} = await axios
        .get(`/v1/documents/user-sign/${documentId}?token=${token}`)
        .catch((err) => {
            throw err;
        });
    return data;
};

DocuGate.putUserSigningDocumentSigningResponse = async (
    documentId,
    coordinateId,
    payload,
    token
) => {
    const {data} = await axios
        .put(
            `/v1/documents/user-sign/${documentId}/coordinate/${coordinateId}?token=${token}`,
            payload
        )
        .catch((err) => {
            throw err;
        });
    return data;
};

// test
DocuGate.upload = async (formData) => {
    const {data} = await axios.post('/v1/fileupload/fileupload', formData);
    return data;
};

export default DocuGate;
