import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {toast} from 'react-toastify';
import {useFormik} from 'formik';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {loginUser} from '@store/reducers/auth';
import {Button, Input, InputNumber} from '@components';
import {
    faEnvelope,
    faLock,
    faPhone,
    faUser
} from '@fortawesome/free-solid-svg-icons';

import * as AuthService from '../../services/auth';

const Register = () => {
    const [isAuthLoading, setAuthLoading] = useState(false);
    const [isAgree, setAgree] = useState(false);
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const history = useHistory();

    const register = async (
        firstName,
        lastName,
        email,
        password,
        phoneNumber
    ) => {
        try {
            setAuthLoading(true);
            const token = await AuthService.registerByAuth({
                firstName,
                lastName,
                email,
                password,
                phoneNumber
            });

            setAuthLoading(false);
            dispatch(loginUser(token));
            // toast.success('Registration is success');
            history.push('/register/success');
        } catch (error) {
            toast.error(
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    'Failed'
            );
            setAuthLoading(false);
        }
    };

    const validateAgree = () => {
        setAgree(!isAgree);
    };

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            password: '',
            passwordRetype: ''
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required('Required'),
            lastName: Yup.string().optional('Optional'),
            email: Yup.string()
                .email('Invalid email address')
                .required('Required'),
            phoneNumber: Yup.number().optional('Optional'),
            password: Yup.string()
                .min(8, 'Must be 8 characters or more')
                .max(30, 'Must be 30 characters or less')
                .matches(/[a-zA-Z]/, {
                    message:
                        'password must contain at least 1 letter and 1 number',
                    excludeEmptyString: false
                })
                .required('Required'),
            passwordRetype: Yup.string()
                .min(8, 'Must be 8 characters or more')
                .max(30, 'Must be 30 characters or less')
                .required('Required')
                .when('password', {
                    is: (val) => !!(val && val.length > 0),
                    then: Yup.string().oneOf(
                        [Yup.ref('password')],
                        'Both password need to be the same'
                    )
                })
        }),
        onSubmit: (values) => {
            register(
                values.firstName,
                values.lastName,
                values.email,
                values.password,
                values.phoneNumber
            );
        }
    });

    useEffect(() => {
        validateAgree();
    }, []);

    document.getElementById('root').classList = 'hold-transition register-page';

    return (
        <div className="register-box">
            <div className="card card-outline card-primary">
                <div className="card-header text-center">
                    <Link to="/" className="h1">
                        <b>ALCA</b>
                        <span>Sign</span>
                    </Link>
                </div>
                <div className="card-body">
                    <p className="login-box-msg">{t('register.registerNew')}</p>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="mb-3">
                            <Input
                                type="text"
                                placeholder="First Name"
                                icon={faUser}
                                formik={formik}
                                formikFieldProps={formik.getFieldProps(
                                    'firstName'
                                )}
                            />
                        </div>
                        <div className="mb-3">
                            <Input
                                type="text"
                                placeholder="Last Name (optional)"
                                icon={faUser}
                                formik={formik}
                                formikFieldProps={formik.getFieldProps(
                                    'lastName'
                                )}
                            />
                        </div>
                        <div className="mb-3">
                            <Input
                                type="email"
                                placeholder="Email"
                                icon={faEnvelope}
                                formik={formik}
                                formikFieldProps={formik.getFieldProps('email')}
                            />
                        </div>
                        <div className="mb-3">
                            <InputNumber
                                type="text"
                                placeholder="Phone Number (optional)"
                                icon={faPhone}
                                formik={formik}
                                formikFieldProps={formik.getFieldProps(
                                    'phoneNumber'
                                )}
                            />
                        </div>
                        <div className="mb-3">
                            <Input
                                type="password"
                                placeholder="Password"
                                icon={faLock}
                                formik={formik}
                                formikFieldProps={formik.getFieldProps(
                                    'password'
                                )}
                            />
                        </div>

                        <div className="mb-3">
                            <Input
                                type="password"
                                placeholder="Retype password"
                                icon={faLock}
                                formik={formik}
                                formikFieldProps={formik.getFieldProps(
                                    'passwordRetype'
                                )}
                            />
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <Button
                                    type="submit"
                                    block
                                    isLoading={isAuthLoading}
                                >
                                    {t('register.getStarted')}
                                </Button>
                            </div>
                        </div>
                    </form>
                    <div className="text-center">
                        <p>- OR -</p>
                    </div>
                    <div>
                        <span>{t('register.alreadyHave')}</span>
                        <Link to="/login" className="text-center">
                            {t('register.loginNow')}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;
